@import "minima";

.site-header {
  border-top: none;
}

.tags {
  color: #828282;
  font-size: 0.75rem;
  margin-top: 0;
}

hr {
  border: none;
  border-top: 1px dashed #aaa;
  margin: 1em;
}

small {
  font-size: 1rem;
  display: block;
  margin: 0 2em;
  font-style: italic;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

p {
  line-height: 1.5em;
  text-align: justify;
  text-indent: 1em;
}

@media screen and (max-width: 500px) {
  p {
    hyphens: auto;
  }
}

figure:not(.highlight) {
  padding: 1em;
  text-align: center;
}

figure > img {
  text-align: center;
  margin: auto;
}

figcaption {
  line-height: 1.5em;
  text-align: center;
  font-size: 1.0rem;
  padding: 0 2em;
  font-style: italic;
}

figcaption.small {
  font-size: 0.9rem;
  color: #444;
}

figcaption code {
  font-style: initial;
}

pre {
  white-space: pre-wrap;
}

.post-content {
  h3 {
    margin-top: 1em;
  }
}